
<template>
  <div class="box99">
    <div>
      <div class="title box-text">更换手机号</div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        
      <div class="items_box">
        <span class="xing">*</span>
          <el-form-item label="手机号" prop="phone">
          <el-input
            v-model="ruleForm.phone"
            placeholder="请输入新的手机号"
            @change="checkPhoneNumber"
          ></el-input>
          <div class="error" v-show="phoneVisable">{{ phoneMessage }}</div>
        </el-form-item>
      </div>

      <div class="items_box">
        <span class="xing1">*</span>
          <el-form-item label="短信验证码" prop="smsCode">
          <div class="text" @click="showPictureCode" v-show="show">
            获取验证码
          </div>
          <div class="text" v-show="!show">再次获取({{ count }}s)</div>
          <el-input
            v-model="ruleForm.smsCode"
            autocomplete="off"
            placeholder="请输入验证码"
            @change="checkCodeNumber"
          ></el-input>
          <div class="error" v-show="codeVisable">{{ codeMessage }}</div>
        </el-form-item>

      </div>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定修改</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <PuzzleCode
      :bind="$attrs"
      :show="showVcode"
      success-text="验证成功"
      fail-text="验证失败"
      slider-text="拖动滑块完成拼图"
      @success="success"
      @close="close"
    />
  </div>
</template>
<script>
import PuzzleCode from "@/components/PuzzleCode";

export function isvalidPhone(str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
}

export default {
  components: {
    PuzzleCode,
  },
  data() {
    //定义一个全局的变量(电话号码)
    var validPhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!isvalidPhone(value)) {
        callback(new Error("手机号码格式不正确"));
      } else {
        callback();
      }
    };
    //定义一个全局的变量(验证码)
    var validSmsCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入验证码"));
      } else {
        this.$http({
          method: "post",
          url: "/code/valid",
          params: {
            smsCode: this.ruleForm.smsCode,
            mobile: this.ruleForm.phone,
          },
        })
          .then((res) => {
            if (res.data === null) {
              callback();
            } else {
              callback(new Error(res.data));
            }
          })
          .catch((res) => {
            console.log(res);
          });
      }
    };
    return {
      ruleForm: {
        phone: "",
        code: "",
      },
      rules: {
        phone: [{ required: true, validator: validPhone, trigger: "blur" }],
        smsCode: [{ required: true, validator: validSmsCode, trigger: "blur" }],
      },
      //验证码倒计时
      show: true,
      count: "",
      timer: null,

      showVcode: false,

      phoneVisable: false,
      codeVisable: false,
      phoneMessage: "",
      codeMessage: "",
      phoneCheck: false,
      codeCheck: false,
    };
  },
  methods: {
    //滑块验证码
    showPictureCode() {
      this.checkPhoneNumber();
      if (this.phoneCheck === true) {
        this.showVcode = true;
      }
    },
    success() {
      this.showVcode = false;
      this.getCode();
    },
    close() {
      this.showVcode = false;
    },
    //生成验证码
    getCode(formName) {
      this.$http({
        method: "get",
        url: "/code/sms",
        params: {
          mobile: this.ruleForm.phone,
          type: "14"
        },
      })
      .then((res) => {
        this.$message({
          showClose: true,
          type: 'info',
          message: res.data
        });
        const TIME_COUNT = 60;
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.show = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.show = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      })
      .catch((res) => {
        console.log(res);
      });
    },
    //提交表单
    submitForm() {
      this.checkPhoneNumber();
      this.checkCodeNumber();
      if (this.phoneCheck && this.codeCheck) {
        this.$http({
          method: "post",
          url: "/home/updatePhone",
          params: {
            phone: this.ruleForm.phone,
          },
        })
          .then((res) => {
            this.$message({
              showClose: true,
              message: "修改成功",
            });
            //清空表单
            this.ruleForm.phone = "";
            this.ruleForm.smsCode = "";
            //清除计时器
            this.show = !show;
            clearInterval(this.timer);
          })
          .catch((res) => {
            console.log(res);
          });
      } else {
        console.log("error submit!!");
        return false;
      }
    },
    //校验手机号
    checkPhoneNumber() {
      var value = this.ruleForm.phone;
      if (!value) {
        this.phoneVisable = true;
        this.phoneMessage = "请输入手机号码";
        this.phoneCheck = false;
      } else if (!isvalidPhone(value)) {
        this.phoneVisable = true;
        this.phoneMessage = "手机号码格式不正确";
        this.phoneCheck = false;
      } else {
        this.phoneVisable = false;
        this.phoneMessage = "";
        this.phoneCheck = true;
      }
    },
    //校验验证码
    checkCodeNumber() {
      var value = this.ruleForm.smsCode;
      if (!value) {
        this.codeVisable = true;
        this.codeMessage = "请输入验证码";
        this.codeCheck = false;
      } else {
        this.$http({
          method: "post",
          url: "/code/valid",
          params: {
            smsCode: this.ruleForm.smsCode,
            mobile: this.ruleForm.phone,
            
          },
        })
          .then((res) => {
            console.log(res.data);
            if (res.data === null) {
              this.codeVisable = false;
              this.codeMessage = "";
              this.codeCheck = true;
            } else {
              this.codeVisable = true;
              this.codeMessage = res.data;
              this.codeCheck = false;
            }
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },
  },
};
</script>
<style scoped>
.box99 {
  width: 990px;
  background: #ffffff;
  border-radius: 6px;
  background-color: #fff;
  height: 600px;
  margin-left :30px;
}
.box-text {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  padding-top: 56px;
  text-align: center;
  margin-bottom: 42px;
}

/* .title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  text-align: center;
  margin-top: 47px;
  margin-bottom: 50px;
} */
::v-deep .el-input__inner {
  width: 404px;
  height: 48px;
  padding-left: 30px;
  border-radius: 24px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
  display: flex;
  margin-left: 200px;
  height: 70px;

}
.items_box{
    position: relative;
}
.xing{
  position: absolute;
  left: 24%;
  top: 17%;
  color: #F56C6C;
}
.xing1{
  position: absolute;
   left: 21%;
  top: 16%;
  color: #F56C6C;
}
::v-deep .el-form-item__content {
  margin-left: 0px !important ;
}
.text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  position: absolute;
  top: 5px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
}

::v-deep .el-button--primary {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  position: relative;
  top: 38px;
  left: 101px;
}
.error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}
</style>