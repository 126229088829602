<template>
  <div class="box">
    <div class="box-text">用户信息</div>
    <!-- 表单 -->
    <div>
      <el-form :model="sysUser" ref="sysUser"
        label-width="100px"
        class="demo-ruleForm"
        :rules="rules1"
      >
        <div class="box8">
          <el-form-item label="真实姓名" prop="FULLNAME">
            <el-input
              v-model="sysUser.FULLNAME"
              placeholder="请输入真实姓名"
              style="margin-left: 60px"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="sysUser.PHONE"
              placeholder="请输入手机号"
              readonly
              style="margin-left: 60px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label=" 人员类型"
            prop="PERSON_TYPE"
            class="become-7"
            v-show="personTypeVisable"
          >
            <el-select
              v-model="sysUser.PERSON_TYPE"
              class="bacome-9"
              placeholder="请选择人员类型"
              style="margin-left: 60px"
            >
              <el-option
                v-for="item in personType"
                :key="item.VALUE"
                :label="item.LABEL"
                :value="item.VALUE"
                style="width: 400px"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="组织机构全称" prop="ORG_NAME">
            <el-autocomplete
            placeholder="请输入组织机构全称"
            v-model="sysUser.ORG_NAME"
            :fetch-suggestions="searchOrgByName"
            :trigger-on-focus="false"
            @select="handleSelectOrg" id="parentOrg"
            ></el-autocomplete>
          </el-form-item>
        </div>
        <!--
        <div class="box7">
          <i></i>
          <p>
            若所在的机构已注册，请输入名字或信用代码进行确认，
            若所属机构没有注册，请完成机构注册。
          </p>
        </div> -->
        <el-form-item label=" 地区" prop="region_county" class="mechanism diqu" >
          <el-select
            v-model="sysUser.region_province"
            placeholder="请选择地区"
            class="mechanism"
            @change="getCity()"
            style="margin-left: 60px"
          >
            <el-option
              v-for="item in province"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="sysUser.region_city"
            placeholder="请选择地区"
            class="mechanism"
            @change="getDistrict()"
            style="margin-left: 15px"
          >
            <el-option
              v-for="item in city"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="sysUser.region_county"
            placeholder="请选择地区"
            class="mechanism"
            style="margin-left: 15px"
            @change="resolveSelectBug"
          >
            <el-option
              v-for="item in district"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label=" "  class="diqu1">
            <el-button
            type="primary"
            @click="submitForm('sysUser')"
            style="left: 0px; top: 0px"
            >确定修改</el-button
          >
       </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  name: "MessageBoxTow",
  props: {
    province: {
      type: Array,
      default() {
        return "";
      },
    },
    organType: {
      type: Array,
      default() {
        return "";
      },
    },
  },
  data() {
     //定义一个全局的变量(上级单位)
    var validParentOrg = (rule, value, callback) => {
      console.log(this.org.parentId)
      if (this.org.parentId == null && value !== null) {
        callback(new Error("您所填写的上级单位不存在"));
      } else {
        callback();
      }
    }; 

    return {
      rules1: {
        FULLNAME: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        PERSON_TYPE: [{ required: true, message: "请选择人员类型", trigger: "blur" }],
        ORG_NAME: [{ required: true, message: "请输入所属机构名称", trigger: "blur" }],
        region_county: [{ required: true, message: "请选择地区", trigger: "blur" }],
      },
      dialogVisible: false,
      form: {
        name: "",
        number: "",
      },
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        date3: "",
        date4: "",
      },
      formLabelWidth: "120px",
      org:{},
      personTypeVisable:true,
      city:[],
      district:[],
      personType:[],
      sysUser:{
        orgName:''
      },
      parentOrgList: []
    };
  },
  mounted(){
    this.getPersonType()
    this.getSysUser()
  },
  methods: {
    resolveSelectBug(){
      this.$forceUpdate();
    },
    //获取sysuser
    getSysUser(){
      this.$http({
        method: "post",
        url: "/home/getUser",
      })
      .then(res=>{
        console.log(res.data)
        this.sysUser = res.data

        if(this.sysUser.RID !== 12){
          this.getCityInit(res.data.AREA.substring(0,2) + '0000')
          this.getDistrictInit(res.data.AREA.substring(0,4) + '00')
          this.sysUser.region_province = this.sysUser.AREA.substring(0,2) + '0000'
          this.sysUser.region_city = this.sysUser.AREA.substring(0,4) + '00'
          this.sysUser.region_county = this.sysUser.AREA
          this.$forceUpdate();
        }
      }).catch(res=>{
        console.log(res)
      }); 
    },
    getOrgNameById(orgid){
       this.$http({
        method: "post",
        url: "/home/getOrgNameById",
        params: {
          id: orgid,
        }
      })
      .then(res=>{
        this.$set(this.sysUser,'orgName', res.data.NAME)
      }).catch(res=>{
        console.log(res)
      }); 
    },
    //获取人员类型
    getPersonType(){
      this.$ajax.post('/userInfo/getPersonType')
        .then(res=>{
console.log(res)

          this.personType = res.data
        }).catch(res=>{
        }); 
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: "post",
            url: "/home/updateSysUser",
            params: {
              guid:this.sysUser.GUID,
              name: this.sysUser.FULLNAME,
              personType: this.sysUser.PERSON_TYPE,
              area: this.sysUser.region_county,
              orgName: this.sysUser.ORG_NAME
            }
          })
          .then(res=>{
            this.$message({
              showClose: true,
              type: 'success',
              message: '修改成功！'
            });    
          }).catch(res=>{
            
          }); 
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //获取2级地区
    getCity(flag) {
      this.$ajax({
        method: "post",
        url: "/userInfo/getCity",
        params: {
          supercode: this.sysUser.region_province,
        },
      })
        .then((res) => {
          this.city = res.data;
          this.sysUser.region_city = "";
          this.sysUser.region_county = "";
        })
        .catch((res) => {});
    },
    //获取3级地区
    getDistrict(flag) {
      this.$ajax({
        method: "post",
        url: "/userInfo/getDistrict",
        params: {
          supercode: this.sysUser.region_city,
        },
      })
        .then((res) => {
          this.district = res.data;
          this.sysUser.region_county = "";
        })
        .catch((res) => {
          console.log(res);
        });
    },
    searchOrg(){
      this.$http({
        method: "post",
        url: "/home/getOrgByName",
        params: {
          name: this.sysUser.orgName,
        },
      })
        .then((res) => {
          if(res.data.length === 0){
            this.dialogVisible = true
            this.org.name = this.sysUser.orgName
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //初始化2级地区
    getCityInit(flag) {
      this.$ajax({
        method: "post",
        url: "/userInfo/getCity",
        params: {
          supercode: flag
        },
      })
        .then((res) => {
          this.city = res.data;
        })
        .catch((res) => {});
    },
    //初始化3级地区
    getDistrictInit(flag) {
      this.$ajax({
        method: "post",
        url: "/userInfo/getDistrict",
        params: {
          supercode: flag
        },
      })
      .then((res) => {
        this.district = res.data;
      })
      .catch((res) => {
        console.log(res);
      });
    },
    //新建机构选择上级机构用
    searchOrgByName(queryString,cb){
      if(queryString !== ''){
        this.$http({
          method: "post",
          url: "/home/searchOrgByName",
          params: {
            name: queryString,
          },
        })
        .then((res) => {
          this.parentOrgList = res.data
          let namearr = res.data;
          for (var i = 0; i < namearr.length; i++) {                   
            namearr[i].value =  namearr[i].NAME;                 
          }
          cb(namearr);
        })
        .catch((res) => {
          console.log(res);
        });
      }
    },
    handleSelectOrg(item) {
      this.sysUser.ORG_NAME =item.NAME;
    },
  },
};
</script>
<style lang='less' scoped>
.diqu{
  margin-left: 150px !important;
}
::v-deep .c-become {
  margin-left: 60px !important;
}
::v-deep .bacome-9 .el-input--suffix .el-input__inner {
  width: 404px;
}
::v-deep .el-form-item__error {
   margin-left: 65px;
}
.diqu1{
  margin-left: 200px !important;
}
.box {
 
  width: 985px;
  // height: 726px;
  background: #ffffff;
  border-radius: 6px;
  background-color: #fff;
  display: inline-block;
  // position: relative;
  top: -170px;
  margin-left: 30px;
  left: 147px;
  // background-color: red;
}
.box-text {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  padding-top: 56px;
  text-align: center;
  margin-bottom: 42px;
}
::v-deep .el-form-item{
  // margin-left: 0px !important;
}

::v-deep .box8 .el-input {
  // width: 404px !important;
}
.box8{
margin-left: 200px;
}
::v-deep .el-input__inner {
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
  border: 1px solid #e5e5e5;
}
::v-deep .el-form-item__label {
  width: 177px;
}
.box9 {
  width: 170px !important;
  margin-right: 20px;
}
// ::v-deep .box9 .el-input__inner{
//   padding-right: 47px!important;
// }
.box7 {
  width: 800px;
  height: 45px;
  margin-left: 300px;
  margin-top: 4px;
  margin-bottom: 15px;
}
.box7 i {
  display: inline-block;
  width: 20px;
  height: 20px;
  // background-color: #3275d6;
  background-image: url("../../assets/734.png");
  margin-bottom: 11px;
}
.box7 p {
  height: 40px;
  width: 375px;
  display: inline-block;
  // margin-left: 10px;
  font-size: 15px;
  font-family: MicrosoftYaHei;
  color: #999999;
}
::v-deep .el-button--default {
  width: 80px;
  height: 38px;
  background: #ffffff;
  border-radius: 19px;
  border: 1px solid #3275d6;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  line-height: 10px;
  position: relative;
  top: -190px;
  right: -80px;
}
::v-deep .el-button--primary {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  position: relative;
  top: 38px;
  left: 194px;
}
::v-deep .box9 .el-input--suffix .el-input__inner {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  padding-left: 48px;
  padding-right: 12px;
}
::v-deep .box9 .el-input--suffix {
  color: #333333 !important;
}
::v-deep .box8 .el-form-item__content .el-input .el-input__inner {
  padding-left: 30px;
}
::v-deep
  .el-dialog__body
  .el-form
  .el-form-item
  .el-form-item__label
  .el-form-item__content
  .el-input
  .el-input__inner {
  margin-left: 60px;
}
.box0 {
  position: absolute;
  top: 0px;
  left: 25px;
}
img {
  width: 20px;
  height: 20px;
  padding: 0 10px;
  position: relative;
  top: 6px;
}

::v-deep .el-dialog__title {
  height: 31px;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  line-height: 31px;
  font-weight: bold;
  text-align: center;
  line-height: 35px;
}
::v-deep .el-dialog__header {
  padding: 0px 0px 0px;
  line-height: 0px;
  margin-top: 57px;
  margin-left: 261px;
  display: inline-block;
  text-align: center;
}
::v-deep .el-dialog {
  width: 643px !important;
  height: 662px;
  background: #ffffff;
  border-radius: 10px;
}
::v-deep .el-dialog__headerbtn {
  margin-right: 9px;
  margin-top: 9px;
}
::v-deep .el-button--primary {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  line-height: 21px;
  display: inline-block;
  margin-right: 101px;
  position: relative;
  left: 10px;
  top: -45px;
}
::v-deep .el-dialog__headerbtn {
  height: 23px;
  width: 23px;
  background-image: url("../../assets/编组 5.png");
  z-index: 9;
}
::v-deep .el-form-item__label {
  background: #ffffff !important;
  border-radius: 10px !important;
  width: 140px !important;
  height: 24px !important;
  font-size: 14px !important;
  font-family: MicrosoftYaHei !important;
  color: #333333 !important;
  line-height: 40px;
  position: absolute;
}
::v-deep .el-input__inner {
  width: 404px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #999999;
  padding-left: 30px;
}
::v-deep .el-input {
  // display: inline !important;
  margin-left: 10px !important;
}
::v-deep .el-input__icon {
  line-height: 50px;
}
::v-deep .el-input--suffix .el-input__inner {
  width: 200px;
}
::v-deep
  .el-dialog__body
  .el-form
  .el-form-item
  .el-form-item__content
  .el-select
  .el-input--suffix
  .el-input__suffix-inner {
  display: inline-block;
  // position: relative;
  top: -12px;
}
::v-deep .el-dialog__body {
  margin-left: 20px;
}
::v-deep .el-select{
  margin-left: 10px !important;
}
::v-deep .el-input__suffix{
  right: 18px;
}
::v-deep .el-form-item__content{
  margin-left: 140px !important;
}
::v-deep .el-autocomplete{
  margin-left: 15px !important;
}
</style>