
<template>
  <div class="box99">
    <div>
      <div class="title box-text">修改密码</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" prop="oldPassword">
          <el-input
            type="password"
            v-model="ruleForm.oldPassword"
            placeholder="请输入原密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            type="password"
            v-model="ruleForm.newPassword"
            autocomplete="off"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="comfirmPasswpord">
          <el-input
            type="password"
            v-model="ruleForm.comfirmPasswpord"
            placeholder="请输入确认密码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定修改</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export function isvalidPassword(str) {
  const reg =
    /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)^.{8,16}$/;
  return reg.test(str);
}

export default {
  data() {
    //定义一个全局的变量(原密码)
    var validOld = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入原密码"));
      } else {
        this.$http({
          method: "post",
          url: "/home/getOldPassword",
        })
          .then(function (res) {
            if (res.data.ORIGIN_PASSWORD === value) {
              callback();
            } else {
              callback(new Error("原密码不正确"));
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    };
    //定义一个全局的变量(密码)
    var vaildNew = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!isvalidPassword(value)) {
        callback(new Error("请输入8-16位数字、字母、特殊字符的组合的密码"));
      } else if (value === this.oldPass) {
        callback(new Error("新密码不能与原密码相同"));
      } else {
        callback();
      }
    };
    //定义一个全局的变量(确认密码)
    var validConfirmPassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error("两次输入的密码不一致，请检查"));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        oldPassword: "",
        newPassword: "",
        comfirmPasswpord: "",
      },
      rules: {
        oldPassword: [{ required: true, validator: validOld, trigger: "blur" }],
        newPassword: [{ required: true, validator: vaildNew, trigger: "blur" }],
        comfirmPasswpord: [
          { required: true, validator: validConfirmPassword, trigger: "blur" },
        ],
      },
      oldPass: "",
    };
  },
  created() {
    this.getOldPassword();
  },
  methods: {
    //获取旧密码
    getOldPassword() {
      this.$http({
        method: "post",
        url: "/home/getOldPassword",
      })
        .then((res) => {
          this.oldPass = res.data.ORIGIN_PASSWORD;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //提交表单 修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: "post",
            url: "/home/changePassword",
            params: {
              password: this.ruleForm.newPassword,
            },
          })
            .then((res) => {
              this.$message.success("修改成功！");
              this.ruleForm.newPassword = "";
              this.ruleForm.oldPassword = "";
              this.ruleForm.comfirmPasswpord = "";
            })
            .catch((res) => {});
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.box99 {
    width: 985px;
    background: #ffffff;
    border-radius: 6px;
    background-color: #fff;
    margin-left: 30px;
    height: 600px;
}
.box-text {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  padding-top: 56px;
  text-align: center;
  margin-bottom: 42px;
}

/* .title {
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #3275d6;
  text-align: center;
  margin-top: 47px;
  margin-bottom: 50px;
} */
::v-deep .el-input__inner {
  width: 404px;
  height: 48px;
  padding-left: 30px;
  border-radius: 24px;
}
::v-deep .el-form-item {
  margin-bottom: 22px;
  display: flex;
  margin-left: 200px;
}
::v-deep .el-form-item__content {
  margin-left: 0px !important ;
}
.text {
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #3275d6;
  position: absolute;
  top: 5px;
  right: 30px;
  z-index: 99;
  cursor: pointer;
}

::v-deep .el-button--primary {
  width: 404px;
  height: 48px;
  background: #3275d6;
  border-radius: 24px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  color: #ffffff;
  position: relative;
  top: 38px;
  left: 101px;
}
</style>