<template>
  <div>
    <Top></Top>
    <div style="display: flex; justify-content: center; background: #f6f6f6">
      <div class="content">
        <!-- <span>回首页</span> -->
        <!-- <div class="con_item">
        <div class="con_item_left">
          <img src="" alt="" />
        </div>
      </div>
      <div></div>
      <div></div>
      <div></div> -->

        <div class="nav">
          <button @click="chageView('comA')" class="btn1">
            用户信息
            <div class="btn4 btn"></div>
          </button>
          <button @click="chageView('comB')" class="btn2">
            更换手机号
            <div class="btn5 btn"></div>
          </button>
          <button @click="chageView('comC')" class="btn3">
            修改密码
            <div class="btn6 btn"></div>
          </button>
        </div>

        <components
          :is="comData"
          :sysUser="sysUser"
          :personType="personType"
          :province="province"
          :organType="organType"
        ></components>
      </div>
    </div>
  </div>
</template>
<script>
import MessageBox1 from "./MessageBox1.vue";
import MessageBoxTow from "./MessageBoxTow.vue";
import MessageBoxThree from "./MessageBoxThree.vue";
import Top from "../../components/top/index.vue";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        name: "",
        number: "",
      },
      comData: "comA",
      dialogTableVisible: false,
      dialogFormVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      formLabelWidth: "120px",
      sysUser: {},
      registerType: [],
      personType: [],
      province: [],
      organType: [],
    };
  },

  components: {
    comA: MessageBoxTow,
    comB: MessageBox1,
    comC: MessageBoxThree,
    Top,
  },
  created() {
    this.getRegisterType();
    this.getSysUser();
    this.getPersonType();
    this.getProvince();
    this.getOrgType();
  },
  methods: {
    chageView(str) {
      this.comData = str;
    },
    //获取人员类型字典
    getRegisterType() {
      this.$ajax
        .post("/userInfo/getRegisterType")
        .then((res) => {
          this.registerType = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //获取sysuser
    getSysUser() {
      this.$http({
        method: "post",
        url: "/home/getUser",
      })
        .then((res) => {
          this.sysUser = res.data;
          console.log(this.sysUser);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    //获取人员类型
    getPersonType() {
      this.$ajax
        .post("/userInfo/getPersonType")
        .then((res) => {
          this.personType = res.data;
        })
        .catch((res) => {});
    },
    //获取1级地区
    getProvince() {
      this.$ajax({
        method: "post",
        url: "/userInfo/getProvince",
      })
        .then((res) => {
          this.province = res.data;
          this.sysUser.region_city = "";
          this.sysUser.region_county = "";
        })
        .catch((res) => {});
    },
    //获取机构类型
    getOrgType() {
      this.$ajax
        .post("/userInfo/getOrgType")
        .then((res) => {
          this.organType = res.data;
        })
        .catch((res) => {});
    }
  },
};
</script>
<style lang='less' scoped>
* {
  margin: 0;
  padding: 0;
}
.content {
  display: flex;
  position: relative;
  background-color: #f6f6f6;
  padding-top: 30px;
  width: 1200px;
  margin-bottom: 90px;
}

.nav button {
  width: 185px !important;
  height: 56px !important;
  font-size: 16px !important;
  font-family: MicrosoftYaHei !important;
  color: #333333 !important;
  line-height: 16px !important;
  text-align: center;
  background-color: #ffffff !important;
  border: none !important;
}
.nav button:hover {
  width: 185px !important;
  height: 56px !important;
  background-color: rgba(50, 117, 214, 0.05) !important;
  font-size: 16px !important;
  font-family: MicrosoftYaHei !important;
  color: #3275d6 !important;
}

.nav {
  width: 180px;
  height: 168px;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  top: 0px;
}
.login-main {
  height: 726px;
}
::v-deep .v-modal {
  z-index: 3000 !important;
  display: none !important;
  position: relative !important;
}
::v-deep .v-modal-enter {
  display: none !important;
  position: relative !important;
}
::v-deep .el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.7);
}
::v-deep .v-modal__wrapper {
  z-index: -1 !important;
}
::v-deep .el-dialog {
  width: 643px;
  height: 428px;
  background: #ffffff;
  border-radius: 10px;
}
.btn {
  width: 7px;
  height: 56px;
  background: #3275d6;
  // border-radius: 6px 0px 0px 0px;
  position: absolute;
  top: 0px;
  // display: none;
}
.btn4 {
  border-radius: 6px 0px 0px 0px;
}
.btn5 {
  top: 56px;
}
.btn6 {
  top: 112px;
  border-radius: 0px 0px 0px 6px;
}
</style>